import type { BookRenderDataPartial } from '@ridi-web/design-system/Book/cjs';
import { addRDTTrackingURIQuery } from '@ridi-web/design-system/Utils/cjs';
import { useCallback } from 'react';

import { sendCustomEvent } from '@/utils/eventClient';

import * as styles from './SearchPopularBooksA.styles';
import { SearchPopularBooksSkeleton } from './SearchPopularBooksASkeleton';

type SearchPopularBooksProps = {
  isPending?: boolean;
  books: BookRenderDataPartial[];
  tabName: string;
  subTabName: string;
};

export const SearchPopularBooks = ({ isPending, books, tabName, subTabName }: SearchPopularBooksProps) => {
  const handleClick = useCallback(
    ({ item_id, item_title, item_index }: { item_id: string; item_title: string; item_index: number }) => {
      sendCustomEvent('search', 'select', 'popular_item', {
        item_id,
        item_title,
        item_index,
        current_tab: tabName,
        current_sub_tab: subTabName,
      });

      globalThis.location.href = addRDTTrackingURIQuery(`/books/${item_id}`, {
        sectionId: 'search_popular',
      });
    },
    [tabName, subTabName],
  );

  if (isPending) {
    return <SearchPopularBooksSkeleton />;
  }

  return (
    <ol css={styles.containerStyle}>
      {books.map((book, i) => (
        <li css={styles.bookContainerStyle} key={`book-${book.bookId}`}>
          <button
            css={styles.bookButtonStyle}
            type="button"
            onClick={_ =>
              handleClick({
                item_id: book.bookId ?? '',
                item_title: book.title ?? '',
                item_index: i + 1,
              })
            }>
            <div css={styles.bookContainerStyle}>
              <div css={styles.bookOrderStyle}>{i + 1}</div>
              <div css={styles.bookTitleWrapperStyle}>
                <span css={styles.bookTitleStyle}>{book.title}</span>
              </div>
            </div>
          </button>
        </li>
      ))}
    </ol>
  );
};
