import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
`;

export const titleStyle = (theme: RIDITheme) => css`
  background: ${theme.colors.fillSkeletonElevated};
  border-radius: 6px;
  width: 120px;
  height: 20px;
`;

export const tabContainerStyle = css`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const tabItemStyle = (theme: RIDITheme) => css`
  background: ${theme.colors.fillSkeletonElevated};
  border-radius: 6px;
  width: 40px;
  height: 24px;
`;
