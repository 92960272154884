import * as styles from './SearchKeywordListSkeleton.styles';

export const SearchKeywordListSkeleton = () => (
  <div data-testid="search-keyword-list-skeleton">
    <div css={styles.titleStyle} />
    <div css={styles.listContainerStyle}>
      <div css={styles.itemAStyle} />
      <div css={styles.itemAStyle} />
      <div css={styles.itemAStyle} />
      <div css={styles.itemBStyle} />
      <div css={styles.itemBStyle} />
      <div css={styles.itemBStyle} />
    </div>
  </div>
);
