import {
  Book,
  BookAuthors,
  BookDefinition,
  BookObserverProvider,
  type BookRenderDataPartial,
  Portrait2InfoComponents,
  Portrait2InfoStyles,
} from '@ridi-web/design-system/Book/cjs';
import { addRDTTrackingURIQuery } from '@ridi-web/design-system/Utils/cjs';
import { useCallback, useMemo } from 'react';

import { useLoggedUser } from '@/hooks/useLoggedUser';
import { sendCustomEvent } from '@/utils/eventClient';

import { BooksBackendOverrideStyles } from './SearchPopularBooksC.styles';
import * as styles from './SearchPopularBooksC.styles';
import { SearchPopularBooksSkeleton } from './SearchPopularBooksCSkeleton';

type SearchPopularBooksProps = {
  isPending?: boolean;
  books: BookRenderDataPartial[];
  tabName: string;
  subTabName: string;
};

export const SearchPopularBooks = ({ isPending, books, tabName, subTabName }: SearchPopularBooksProps) => {
  const user = useLoggedUser();
  const isAdultVerifiedUser = useMemo(() => (user && user.adultVerification.status === 'VERIFIED') ?? false, [user]);

  const definition = useMemo(
    () => ({
      attributes: {
        isAdultVerifiedUser,
      },
      styles: [Portrait2InfoStyles, BooksBackendOverrideStyles],
      components: {
        ...Portrait2InfoComponents,
        BookSetbook: () => null,
        BookAuthors: BookAuthors.withOptions({ clipBreakPoint: Number.POSITIVE_INFINITY, clipCount: null }),
      },
    }),
    [isAdultVerifiedUser],
  );

  const handleClick = useCallback(
    ({ item_id, item_title, item_index }: { item_id: string; item_title: string; item_index: number }) => {
      sendCustomEvent('search', 'select', 'popular_item', {
        item_id,
        item_title,
        item_index,
        current_tab: tabName,
        current_sub_tab: subTabName,
      });

      globalThis.location.href = addRDTTrackingURIQuery(`/books/${item_id}`, {
        sectionId: 'search_popular',
      });
    },
    [tabName, subTabName],
  );

  if (isPending) {
    return <SearchPopularBooksSkeleton />;
  }

  return (
    <BookObserverProvider>
      <BookDefinition definition={definition}>
        {({ className }) => (
          <ol className={className} css={styles.gridStyle}>
            {books.map((book, i) => (
              <li css={styles.bookContainerStyle} key={book.bookId}>
                {/* NOTE: button을 쓰면 position: absolute와 bottom 속성 조합이 먹히지 않는 문제가 있는데 원인 파악을 못해서 div로 사용 */}
                <div
                  role="button"
                  tabIndex={0}
                  css={styles.bookButtonStyle}
                  onKeyDown={ev => (ev.target as HTMLElement).click()}
                  onClick={_ =>
                    handleClick({
                      item_id: book.bookId ?? '',
                      item_title: book.title ?? '',
                      item_index: i + 1,
                    })
                  }>
                  <Book data={book} />
                </div>
              </li>
            ))}
          </ol>
        )}
      </BookDefinition>
    </BookObserverProvider>
  );
};
