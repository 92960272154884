import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const bookContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 5.4px);
`;

export const bookThumbnailStyle = (theme: RIDITheme) => css`
  height: 203px;
  background: ${theme.colors.fillSkeletonElevated};
  border-radius: 4px;
`;

export const bookTitleStyle = (theme: RIDITheme) => css`
  margin-top: 6px;
  background: ${theme.colors.fillSkeletonElevated};
  border-radius: 4px;
  height: 18px;
`;

export const bookAuthorsStyle = (theme: RIDITheme) => css`
  margin-top: 4px;
  background: ${theme.colors.fillSkeletonElevated};
  border-radius: 4px;
  width: 71%;
  height: 16px;
  margin-bottom: 28px;
`;
