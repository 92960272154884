import { convertBackendsCamelBookToRenderData as convertToRenderData } from '@ridi-web/design-system/NewBook/cjs';
import { useCallback, useLayoutEffect, useMemo } from 'react';

import { useLoggedUser } from '@/hooks/useLoggedUser';
import { useSingleFeatureFlag } from '@/hooks/useSingleFeatureFlag';
import {
  FrequentlySearchedBooksCategory,
  FrequentlySearchedBooksCategoryItem,
  useFrequentlySearchedBooksQuery,
} from '@/queries/search/useFrequentlySearcedBooksQuery';
import { sendCustomEvent } from '@/utils/eventClient';

import { useLocalStorage } from '../hooks/useLocalStorage';
import { FakeSelect } from './FakeSelect';
import * as styles from './SearchPopularBooks.styles';
import { SearchPopularBooks as SearchPopularBooksA } from './SearchPopularBooksA';
import { SearchPopularBooks as SearchPopularBooksB } from './SearchPopularBooksB';
import { SearchPopularBooks as SearchPopularBooksC } from './SearchPopularBooksC';
import { SearchPopularBooksTabsSkeleton } from './SearchPopularBooksTabsSkeleton';

type SearchPopularBooksProps = {
  title: string;
};

const SEARCH_POPULAR_BOOKS_TABS = 'SEARCH_POPULAR_BOOKS_CATEGORY';

function isObject(object: unknown) {
  if (typeof object !== 'object' || object === null) {
    return false;
  }
  return Object.getPrototypeOf(object) === Object.prototype;
}

/*
 * 탭별 상태를 로컬에 저장하기 위해 사용
 * frequentlySearchedBooks API에서 카테고리의 id를 내려주지 않음
 * 키에는 부모 카테고리 genre, 값에 자식 카테고리의 genre를 저장
 */
type LocalCategory = {
  parentGenre: string;
  subCategories: {
    [parentGenre: string]: string;
  };
};

export const SearchPopularBooks = ({ title }: SearchPopularBooksProps) => {
  const user = useLoggedUser();
  const isVerifiedAdult = useMemo(() => user && user.adultVerification.status === 'VERIFIED', [user]);
  const featureFlagExperimental = useSingleFeatureFlag('servicebackends-exp-frequently-searched-books');
  const frequentlySearchedBooks = useFrequentlySearchedBooksQuery({ platform: 'web', isVerifiedAdult });
  const frequentlySearchedBooksData = useMemo(() => frequentlySearchedBooks.data ?? null, [frequentlySearchedBooks]);

  const [localCategory, setLocalCategory] = useLocalStorage<LocalCategory>(
    SEARCH_POPULAR_BOOKS_TABS,
    {
      parentGenre: '',
      subCategories: {},
    },
    category =>
      isObject(category) && !!category.parentGenre && !!category.subCategories && isObject(category.subCategories),
  );

  const isError = featureFlagExperimental.error || frequentlySearchedBooks.error;

  // 초기 유효성 검사
  useLayoutEffect(() => {
    const data = frequentlySearchedBooksData;
    if (!data) {
      return;
    }

    const defaultCategory = data.find(item => item.title === '도서') ?? data[0];

    setLocalCategory(previous => {
      const parentGenre = data.some(({ genre }) => previous.parentGenre === genre)
        ? previous.parentGenre
        : defaultCategory.genre;
      const previousSubCagetory = previous.subCategories[parentGenre];

      const categoryItems = data.find(({ genre }) => genre === parentGenre)?.categoryItems;
      const subCategory = categoryItems?.some(({ category }) => category === previousSubCagetory)
        ? previousSubCagetory
        : categoryItems?.[0].category ?? '';

      return {
        parentGenre,
        subCategories: {
          ...previous.subCategories,
          [parentGenre]: subCategory,
        },
      };
    });
  }, [frequentlySearchedBooksData, setLocalCategory]);

  const categoryList = useMemo(() => frequentlySearchedBooks.data ?? [], [frequentlySearchedBooks]);

  const selectedCategory = useMemo(() => {
    const data = frequentlySearchedBooksData;
    if (!data) {
      return null;
    }

    return data.find(({ genre }) => genre === localCategory.parentGenre) ?? null;
  }, [localCategory, frequentlySearchedBooksData]);

  const categoryItems = useMemo(() => selectedCategory?.categoryItems ?? [], [selectedCategory]);

  const selectedCategoryItemCategory = useMemo(
    () => localCategory.subCategories[localCategory.parentGenre],
    [localCategory],
  );

  const selectedCategoryItem = useMemo(
    () => categoryItems.find(({ category: categoryName }) => categoryName === selectedCategoryItemCategory) ?? null,
    [selectedCategoryItemCategory, categoryItems],
  );

  // 서버에서 올바르게 데이터를 받으면 title이 있습니다.
  const tabName = selectedCategory?.title ?? '';
  const subTabName = categoryItems.length > 1 ? selectedCategoryItem?.title ?? 'null' : 'null';

  const handleCategoryClick = useCallback(
    (nextCategoryItem: FrequentlySearchedBooksCategory) => {
      const { data } = frequentlySearchedBooks;

      if (!data) {
        return;
      }
      const { genre: nextGenre } = nextCategoryItem;

      setLocalCategory(previous => {
        const previousSubCategory = previous.subCategories[nextGenre];
        const nextCategoryItems = data.find(({ genre }) => genre === nextGenre)?.categoryItems ?? [];

        return {
          parentGenre: nextGenre,
          subCategories: {
            ...previous.subCategories,
            [nextGenre]: nextCategoryItems.some(({ category }) => category === previousSubCategory)
              ? previousSubCategory
              : nextCategoryItems[0].category,
          },
        };
      });

      sendCustomEvent('search', 'select', 'popular_tab', {
        tab: nextCategoryItem.title,
        current_tab: tabName,
        current_sub_tab: subTabName,
      });
    },
    [setLocalCategory, frequentlySearchedBooks, tabName, subTabName],
  );

  const handleSubCategoryChange = useCallback(
    (nextSubCategoryItem: FrequentlySearchedBooksCategoryItem) => {
      setLocalCategory(previous => ({
        ...previous,
        subCategories: {
          ...previous.subCategories,
          [previous.parentGenre]: nextSubCategoryItem.category,
        },
      }));

      sendCustomEvent('search', 'select', 'popular_sub_tab', {
        sub_tab: nextSubCategoryItem.title,
        current_tab: tabName,
        current_sub_tab: subTabName,
      });
    },
    [setLocalCategory, tabName, subTabName],
  );

  const isPending = featureFlagExperimental.isPending || frequentlySearchedBooks.isLoading;

  const searchPopularBooksTabComponent = useMemo(
    () =>
      isPending ? (
        <SearchPopularBooksTabsSkeleton />
      ) : (
        <>
          <div css={styles.titleStyle}>{title}</div>
          <nav css={styles.categoryContainerStyle}>
            <div css={styles.categoryTabListStyle}>
              {categoryList.map((item, i) => (
                <button
                  type="button"
                  css={styles.tabItemStyle(item.genre === localCategory.parentGenre)}
                  onClick={() => handleCategoryClick(item)}
                  key={`tab-item-${i.toString()}`}>
                  {item.title}
                </button>
              ))}
            </div>
            {categoryItems.length > 1 && (
              <FakeSelect
                label={selectedCategoryItem?.title ?? ''}
                list={categoryItems ?? []}
                value={selectedCategoryItemCategory}
                onChange={handleSubCategoryChange}
              />
            )}
          </nav>
        </>
      ),
    [
      isPending,
      categoryList,
      categoryItems,
      localCategory,
      handleCategoryClick,
      handleSubCategoryChange,
      selectedCategoryItem?.title,
      selectedCategoryItemCategory,
      title,
    ],
  );

  const searchPopularBooksComponent = useMemo(() => {
    const { data: flagValue, error } = featureFlagExperimental;
    if (error) {
      return null;
    }
    const books = (selectedCategoryItem?.bookItems ?? []).map(item => convertToRenderData(item.book, item.badges));

    if (flagValue === 'A') {
      return <SearchPopularBooksA isPending={isPending} books={books} tabName={tabName} subTabName={subTabName} />;
    }
    if (flagValue === 'B') {
      return <SearchPopularBooksB isPending={isPending} books={books} tabName={tabName} subTabName={subTabName} />;
    }
    if (flagValue === 'C') {
      return <SearchPopularBooksC isPending={isPending} books={books} tabName={tabName} subTabName={subTabName} />;
    }

    return null;
  }, [featureFlagExperimental, selectedCategoryItem, tabName, subTabName, isPending]);

  return searchPopularBooksComponent && !isError ? (
    <div css={styles.containerStyle}>
      {searchPopularBooksTabComponent}
      {searchPopularBooksComponent}
    </div>
  ) : null;
};
