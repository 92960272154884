import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { IconBadgeAdult } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { usePageContext } from '@/components/common/PageContext';
import { Switch } from '@/components/common/Switch';
import { instantSearchSelector } from '@/features/search/instantSearch/instantSearchSlice';
import { useSanitizedMarkup } from '@/hooks/useSanitizedMarkup';
import { SearchAuthor as SearchAuthorType, SearchBook as SearchBookType } from '@/models/searchApi/Search/SearchType';
import { sendClickEvent } from '@/utils/eventClient';

import { getAuthorLabel } from '../utils/getAuthorLabel';
import * as styles from './SearchResult.styles';

const StyledResultItem = styled.li<{ focused?: boolean }>`
  ${({ theme }) => styles.resultItemStyle(theme)};
  ${({ theme, focused }) => focused && styles.resultItemFocusedStyle(theme)};
`;

interface ItemProps {
  keyword: string;
  href: string;
  focused: boolean;
  onClick: () => void;
  onHover: () => void;
  onLeave: () => void;
}

interface AuthorItemProps extends ItemProps {
  author: SearchAuthorType;
}

const AuthorItem = ({
  author,
  keyword,
  href,
  focused,
  onClick,
  onHover,
  onLeave,
}: AuthorItemProps): ReactJSX.Element => {
  const authorHighlightMarkup = useSanitizedMarkup(author.highlight.name || '');
  const pageContext = usePageContext();

  return (
    <StyledResultItem focused={focused} data-testid="search-author-item">
      <TrackClickEvent
        screenName={pageContext.screenName}
        target="instant_item"
        params={{ ...pageContext.params, item: 'author', item_id: author.id, item_name: author.name, keyword }}>
        <a
          href={href}
          css={styles.resultItemButtonStyle}
          tabIndex={-1}
          onClick={onClick}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}>
          {author.highlight.name ? (
            <span css={styles.authorAuthorStyle} {...authorHighlightMarkup} />
          ) : (
            <span css={styles.authorAuthorStyle}>{author.name}</span>
          )}
          <span css={[styles.resultItemDescriptionStyle, styles.authorBookStyle]}>
            {author.popular_book_title}
            {author.book_count > 1 ? ` 외 ${author.book_count - 1}권` : ''}
          </span>
        </a>
      </TrackClickEvent>
    </StyledResultItem>
  );
};

interface BookItemProps extends ItemProps {
  book: SearchBookType;
}

const BookItem = ({ book, keyword, href, focused, onClick, onHover, onLeave }: BookItemProps): ReactJSX.Element => {
  const titleHighlightMarkup = useSanitizedMarkup(book.highlight.web_title_title || '');
  const pageContext = usePageContext();

  return (
    <StyledResultItem focused={focused} data-testid="search-book-item">
      <TrackClickEvent
        screenName={pageContext.screenName}
        target="instant_item"
        params={{ ...pageContext.params, item: 'book', item_id: book.b_id, item_name: book.web_title_title, keyword }}>
        <a
          href={href}
          css={[styles.resultItemButtonStyle, styles.bookResultItemButtonStyle]}
          tabIndex={-1}
          onClick={onClick}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}>
          {book.highlight.web_title_title ? (
            <span css={styles.bookTitleStyle} {...titleHighlightMarkup} />
          ) : (
            <span css={styles.bookTitleStyle}>{book.web_title_title}</span>
          )}
          <div css={[styles.resultItemDescriptionStyle, styles.bookResultItemDescriptionStyle]}>
            <div css={styles.bookResultItemDescriptionContentStyle}>
              <span css={styles.bookAuthorPublisherStyle}>
                {(book.authors_info && getAuthorLabel(book.authors_info)) ?? book.author}
              </span>
              <div css={styles.bookVerticalDividerStyle} />
              <span css={styles.bookAuthorPublisherStyle}>{book.publisher}</span>
            </div>
            {book.age_limit > 18 && (
              <div css={styles.adultBadgeWrapperStyle}>
                <IconBadgeAdult css={styles.adultBadgeStyle} aria-label="성인 전용 도서" />
              </div>
            )}
          </div>
        </a>
      </TrackClickEvent>
    </StyledResultItem>
  );
};

interface SearchResultProps {
  className?: string;
  keyword: string;
  focusedIndex: number | null;
  setFocusedIndex: (index: number | null) => void;
  isAdultExcluded: boolean;
  setIsAdultExcluded: (isAdultExcluded: boolean) => void;
  getBookUrl: (book: SearchBookType, index: number) => string;
  getAuthorUrl: (author: SearchAuthorType) => string;
  goToInstantUrl: (url: string) => void;
}

export const SearchResult = ({
  className,
  keyword,
  focusedIndex,
  setFocusedIndex,
  isAdultExcluded,
  setIsAdultExcluded,
  getAuthorUrl,
  getBookUrl,
  goToInstantUrl,
}: SearchResultProps): ReactJSX.Element => {
  const { result } = useSelector(instantSearchSelector);
  const pageContext = usePageContext();

  if (!result) {
    return <></>;
  }

  const {
    book: { books },
    author: { authors },
  } = result;

  const onHover = (index: number) => () => setFocusedIndex(index);
  const onLeave = () => setFocusedIndex(null);
  const onClick = (index: number) => () => {
    const url =
      index < authors.length ? getAuthorUrl(authors[index]) : getBookUrl(books[index - authors.length], index);
    goToInstantUrl(url);
  };

  return (
    <div className={className}>
      {authors.length > 0 && (
        <>
          <span css={styles.resultHeaderStyle}>작가</span>
          <ul css={styles.resultWrapperStyle}>
            {authors.map((author, index) => (
              <AuthorItem
                key={author.id}
                author={author}
                href={getAuthorUrl(author)}
                keyword={keyword}
                onClick={onClick(index)}
                onHover={onHover(index)}
                onLeave={onLeave}
                focused={index === focusedIndex}
              />
            ))}
          </ul>

          {books.length > 0 && <hr css={styles.dividerStyle} />}
        </>
      )}

      {books.length > 0 && (
        <>
          <span css={styles.resultHeaderStyle}>작품</span>
          <ul css={styles.resultWrapperStyle}>
            {books.map((book, index) => {
              const itemIndex = authors.length + index;
              return (
                <BookItem
                  key={book.b_id}
                  book={book}
                  href={getBookUrl(book, itemIndex)}
                  keyword={keyword}
                  onClick={onClick(itemIndex)}
                  onHover={onHover(itemIndex)}
                  onLeave={onLeave}
                  focused={itemIndex === focusedIndex}
                />
              );
            })}
          </ul>
        </>
      )}

      <label css={styles.adultExcludeStyle}>
        성인 제외
        <Switch
          checked={isAdultExcluded}
          onChange={newValue => {
            // onClick은 newValue가 바뀌기 전/후 모두 실행될 수 있기 때문에 <TrackClickEvent> 를 사용하여서는 안된다
            sendClickEvent(pageContext.screenName, 'search_toggle_adult', {
              ...pageContext.params,
              new_adult_excluded: newValue,
            });
            setIsAdultExcluded(newValue);
          }}
        />
      </label>
    </div>
  );
};
