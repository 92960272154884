import * as styles from './SearchPopularBooksTabsSkeleton.styles';

export const SearchPopularBooksTabsSkeleton = () => (
  <div css={styles.containerStyle}>
    <div css={styles.titleStyle} />
    <ul css={styles.tabContainerStyle}>
      <li css={styles.tabItemStyle} />
      <li css={styles.tabItemStyle} />
      <li css={styles.tabItemStyle} />
      <li css={styles.tabItemStyle} />
    </ul>
  </div>
);
