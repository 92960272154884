import { createContext, ReactNode, useContext } from 'react';

import type { EventParamsType } from '@/utils/eventClient';

export type PageContextType = {
  readonly screenName: string;
  readonly params?: EventParamsType;
  /**
   * genrehome_select_* 이벤트에만 별도로 추가하기 위한 파라미터
   * @see https://www.notion.so/ridi/APP-QA-1c32d79d770780138135d08dcaf968f0?pvs=4#1c42d79d77078022ad7dca365cb478ea
   */
  readonly currentNavigationParams?: {
    current_genre: string;
    current_tab?: string;
    current_sub_tab?: string;
  };
};

export const PageContext = createContext<PageContextType>({
  screenName: '',
  params: undefined,
  currentNavigationParams: undefined,
});

/**
 *
 * event tracking에 사용되는 page 레벨의 데이터 context
 *
 * @param {PageContextType} value event tracking 에 사용되는 page 레벨의 데이터
 * @param {ReactNode} children 컴포넌트
 */

export const PageContextProvider = ({
  value,
  children,
}: {
  value: PageContextType;
  children: ReactNode;
}): ReactJSX.Element => <PageContext.Provider value={value}>{children}</PageContext.Provider>;

export const usePageContext = (): PageContextType => {
  const pageContext = useContext(PageContext);

  return pageContext;
};
