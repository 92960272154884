import { useEffect, useState } from 'react';

import type { FeatureFlagKey, FeatureFlagValue } from '@/utils/featureFlag';
import { requestWithRefreshAuth } from '@/utils/request';

type FeatureFlagResponse<Data> = {
  success: boolean;
  messages: string | null;
  data: {
    flagKey: string;
    flagValue: Data;
  };
};

export function useSingleFeatureFlag<Key extends FeatureFlagKey>(flagKey: Key) {
  type Return = FeatureFlagValue<Key>;

  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState<Return | null>(null);
  const [errorState, setErrorState] = useState<unknown | null>(null);

  useEffect(() => {
    let tempValue: Return | null = null;
    let tempError: Error | null = null;
    requestWithRefreshAuth<Error, FeatureFlagResponse<Return>>({
      host: process.env.NEXT_PUBLIC_BACKENDS_API,
      method: 'GET',
      pathname: `/v2/feature-flag/${flagKey}`,
    })
      .then(([error, res]) => {
        if (error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          tempError = error;
        } else {
          tempValue = res.data.data.flagValue;
        }
      })
      .finally(() => {
        setData(tempValue);
        setErrorState(tempError);
        setIsPending(false);
      });
  }, [flagKey]);

  return {
    error: errorState,
    isPending,
    data,
  } as
    | {
        error: null;
        isPending: true;
        data: null;
      }
    | {
        error: null;
        isPending: false;
        data: Return;
      }
    | {
        error: Error;
        isPending: false;
        data: null;
      };
}
