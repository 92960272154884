import * as styles from './SearchPopularBooksASkeleton.styles';

const list = Array.from({ length: 10 }, () => null);

export const SearchPopularBooksSkeleton = () => (
  <ul css={styles.containerStyle}>
    {list.map((_, i) => (
      <li key={`data-${i.toString()}`} css={styles.bookContainerStyle}>
        <div css={styles.bookOrderStyle} />
        <div css={styles.bookTitleStyle} />
      </li>
    ))}
  </ul>
);
