import { getParsedCookie } from '@ridi-web/common';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IconOutLink } from '@/assets/svgs/system';
import {
  currentNavigationRouteSelector,
  globalNavigationSelector,
  updateVisitedNavigationIdsAction,
  visitedNavigationIdsSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { findLeafNavigation } from '@/utils/navigation';

import { useOnScrollRightEnd } from '../../../hooks/useOnScrollRightEnd';
import { usePageContext } from '../PageContext';
import * as styles from './GlobalNavigationBarBottom.styles';
import { GlobalNavigationBarLink } from './GlobalNavigationBarLink';

const emptyNavigation: Navigation = {
  id: -1,
  title: '',
  path: '',
  image_url: null,
  resource_url: null,
  is_default: false,
  view_id: null,
  children: [],
};

type Props = {
  onScrollRightEnd: (isAtRightEnd: boolean) => void;
  isGenreHomeRoute?: boolean;
  isSearchPage?: boolean;
};

export const GlobalNavigationBarBottom = ({
  onScrollRightEnd,
  isGenreHomeRoute,
  isSearchPage,
}: Props): ReactJSX.Element => {
  const dispatch = useAppDispatch();

  const pageContext = usePageContext();
  const globalNavigation = useSelector(globalNavigationSelector);
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);
  const visitedNavigationIds = useSelector(visitedNavigationIdsSelector);

  useEffect(() => {
    if (!visitedNavigationIds) {
      dispatch(updateVisitedNavigationIdsAction(getParsedCookie(document.cookie)?.ridi_nav));
    }
  }, [dispatch, visitedNavigationIds]);

  const navigationItems = useMemo(
    () =>
      globalNavigation
        ?.map(item => ({
          ...item,
          isSelected: currentNavigationRoute?.some(route => route.id === item.id),
          openInNewTab: false,
          leafNavigation: findLeafNavigation(item, visitedNavigationIds),
          icon: <></>,
        }))
        .concat({
          ...emptyNavigation,
          title: '셀렉트',
          isSelected: false,
          openInNewTab: true,
          leafNavigation: {
            ...emptyNavigation,
            path: 'https://select.ridibooks.com',
          },
          icon: <IconOutLink />,
        }),
    [globalNavigation, currentNavigationRoute, visitedNavigationIds],
  );

  const scrollableRef = useOnScrollRightEnd({ onScrollRightEnd });

  return (
    <nav
      css={[
        styles.topItemsStyle,
        isGenreHomeRoute ? styles.linkGenreHomeStyle : styles.linkNotGenreHomeStyle,
        isSearchPage && styles.hideOnMobile,
      ]}
      ref={scrollableRef}>
      {navigationItems?.map(item => {
        const { path, title } = item.leafNavigation;
        const eventParams = {
          ...pageContext.params,
          ...pageContext.currentNavigationParams,
          genre: item.title,
          tab: item.children.find(child => path.startsWith(`${child.path}/`))?.title,
          sub_tab: title || undefined,
          path,
        };
        return (
          <GlobalNavigationBarLink
            key={item.id}
            css={[
              styles.topItemLinkStyle,
              item.isSelected && styles.topItemSelectedLinkStyle,
              !visitedNavigationIds && styles.disableClickWhileHydrationStyle,
            ]}
            trackingParams={{
              screenName: pageContext.screenName,
              target: 'navigation_top',
              params: eventParams,
            }}
            openInNewTab={item.openInNewTab}
            href={path}>
            {item.title}
            {item.icon}
          </GlobalNavigationBarLink>
        );
      })}
    </nav>
  );
};
