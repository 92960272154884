import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 16px;
`;

export const bookContainerStyle = css`
  display: flex;
  flex-direction: row;
  min-height: 66px;
  cursor: pointer;
`;

export const bookThumbnailStyle = (theme: RIDITheme) => css`
  position: relative;
  min-width: 46px;
  width: 46px;
  height: 66px;
  border-radius: 4px;
  background: ${theme.colors.fillSkeletonElevated};

  img {
    border-radius: 4px;
  }
`;

export const bookOrderStyle = (theme: RIDITheme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 100%;

  :before {
    content: '';
    width: 18px;
    height: 24px;
    border-radius: 4px;
    background: ${theme.colors.fillSkeletonElevated};
  }
`;

export const bookMetaDataStyle = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  flex: 1;
`;

export const bookTitleStyle = (theme: RIDITheme) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${theme.colors.fillSkeletonElevated};
  flex: 1;
  width: 100%;
  max-width: 217px;
  max-height: 18px;
`;

export const bookAuthorsStyle = (theme: RIDITheme) => css`
  background: ${theme.colors.fillSkeletonElevated};
  flex: 1;
  width: 100%;
  max-width: 132px;
  max-height: 16px;
`;
