import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { IconSearchClear, IconSearchSearch } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { usePageContext } from '@/components/common/PageContext';
import { BreakPoint } from '@/components/styles/media';
import { a11y } from '@/components/styles/reset';
import { shouldUseHistoryNavigationSelector } from '@/features/global/globalNavigationBar';
import { isDesktopSelector, isMobileSelector, isTabletSelector } from '@/features/global/variables/variablesSlice';
import {
  instantSearchSelector,
  resetInstantSearchAction,
  updateInstantSearchAction,
} from '@/features/search/instantSearch/instantSearchSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { SearchAuthor, SearchBook } from '@/models/searchApi/Search/SearchType';
import { useSearchComponentsQuery } from '@/queries/search/useSearchComponentsQuery';
import { MAX_SEARCH_KEYWORD_LENGTH } from '@/services/searchApi/search/searchService';
import { sendClickEvent, sendSubmitEvent } from '@/utils/eventClient';
import { throttle } from '@/utils/functions';
import { addRDTTrackingURIQuery } from '@/utils/query';
import { decodeURLSearchRequestQuery, encodeSearchURLRequestQuery } from '@/utils/search';

import { useAdultExclude } from '../hooks/useAdultExclude';
import { useSearchHistoryForSLP } from '../hooks/useSearchHistoryForSLP';
import { SearchHistoryExperimental } from '../SearchHistory';
import { SearchKeywordList } from '../SearchKeywordList/SearchKeywordList';
import { SearchPopularBooks } from '../SearchPopularBooks';
import { SearchResult } from '../SearchResult';
import * as styles from './SearchBar.styles';

const StyledSearchForm = styled.form<{ focused: boolean; isHide: boolean; isMobileDevice: boolean }>`
  ${({ focused, isMobileDevice }) => styles.searchBarStyle(focused && isMobileDevice)}
  ${({ isHide, focused, isMobileDevice }) => isHide && styles.searchBarHideStyle(focused && isMobileDevice)};
  ${styles.formStyle};
  ${({ focused, isMobileDevice }) => focused && styles.formFocusedStyle(isMobileDevice)};
`;

const StyledSearchBoxWrapper = styled.div<{ focused: boolean; isMobile: boolean; isOpen: boolean }>`
  ${({ focused, isMobile }) => styles.searchBoxWrapperStyle(focused && isMobile)};
  ${({ focused, isMobile, theme }) => focused && styles.searchBoxWrapperFocusedStyle(focused && isMobile)(theme)};
  ${({ focused }) => !focused && styles.searchBoxWrapperUnfocusedStyle};
  ${({ isMobile, isOpen, theme }) => isMobile && isOpen && styles.searchBoxWrapperMobileStyle(theme)};
`;

const StyledSearchPopupWrapper = styled.div<{ focused: boolean; isMobileDevice: boolean }>`
  ${({ focused, isMobileDevice, theme }) => styles.popupWrapperStyle(focused && isMobileDevice)(theme)};
  ${({ focused, isMobileDevice }) => focused && styles.popupWrapperFocusedStyle(focused && isMobileDevice)};
`;

interface SearchBarProps {
  isHide: boolean;
}

export interface SearchBarHandle {
  focus: () => void;
}

export const SearchBar = forwardRef(({ isHide }: SearchBarProps, ref) => {
  const pageContext = usePageContext();
  const router = useRouter();
  const isFocusingEnabled = useSelector(isDesktopSelector);
  const searchQuery = useMemo(() => decodeURLSearchRequestQuery(router.query), [router.query]);
  const {
    data: searchComponentsData,
    error: searchComponentsError,
    isLoading: isLoadingSearchComponents,
    isFallback: isFallbackSearchComponents,
  } = useSearchComponentsQuery({ page: 'landing', platform: 'web' });
  const isSearchImprovementExperimental = !isLoadingSearchComponents && !isFallbackSearchComponents;
  const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall, false);
  const [keyword, setKeyword] = useState(searchQuery.keyword ?? '');
  const [isAdultExcluded, setIsAdultExcluded] = useAdultExclude();
  const [isFocused, setIsFocused] = useState(false);
  const [focusedIndexRaw, setFocusedIndexRaw] = useState<number | null>(null);

  const isTabletDevice = useSelector(isTabletSelector);
  const isMobileDevice = useSelector(isMobileSelector) || isTabletDevice;

  const focusedIndex = isFocusingEnabled ? focusedIndexRaw : null;
  const setFocusedIndex = useCallback(
    (newFocusedIndex: number | null) => {
      if (isFocusingEnabled) {
        setFocusedIndexRaw(newFocusedIndex);
      }
    },
    [isFocusingEnabled],
  );

  const { result } = useSelector(instantSearchSelector);
  const authorsCount = result ? result.author.authors.length : 0;
  const booksCount = result ? result.book.books.length : 0;

  const history = useSearchHistoryForSLP(isSearchImprovementExperimental);
  const itemsCount = result ? authorsCount + booksCount : history.history.length;

  const goToInstantUrl = useCallback((url: string) => {
    window.location.href = url;
  }, []);

  const shouldUseHistoryNavigation = useSelector(shouldUseHistoryNavigationSelector);
  const goToSearchResult = useCallback(
    (searchKeyword: string) => {
      const trimmedKeyword = searchKeyword.trim();
      if (!trimmedKeyword) {
        return;
      }

      const params = encodeSearchURLRequestQuery({
        keyword: trimmedKeyword,
        isAdultExcluded,
      });
      const url = `/search?${params}`;

      history.addHistory(trimmedKeyword);

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      // TODO: 최근 검색어로 검색페이지 진입시 인풋에 텍스트가 입력되지 않는 버그때문에 추가, 현재는 간헐적으로 발생, 필요여부 확인 후 제거
      setKeyword(trimmedKeyword);

      if (shouldUseHistoryNavigation) {
        router.push(url);
        return;
      }

      window.location.href = url;
    },
    [router, isAdultExcluded, history, shouldUseHistoryNavigation],
  );

  const defaultInstantParams = useMemo<string>(() => {
    const params = new URLSearchParams({
      _s: 'instant',
      _q: keyword,
    });

    return params.toString();
  }, [keyword]);

  const getAuthorUrl = useCallback(
    (author: SearchAuthor) => `/author/${author.id}?${defaultInstantParams}`,
    [defaultInstantParams],
  );
  const getBookUrl = useCallback(
    (book: SearchBook, index: number) =>
      addRDTTrackingURIQuery(`/books/${book.b_id}?${defaultInstantParams}`, {
        sectionId: 'search_instant',
        sectionItemIdx: index,
        sectionArg: keyword,
      }),
    [defaultInstantParams, keyword],
  );

  // 위 아래키로 선택 이동
  const onKeyDown = (event: KeyboardEvent) => {
    let newPosition = focusedIndex ?? itemsCount;

    switch (event.key) {
      case 'ArrowDown':
      case 'Down':
        newPosition += 1;
        break;

      case 'ArrowUp':
      case 'Up':
        newPosition -= 1;
        break;

      case 'Enter':
        // submit 이벤트 호출을 막음
        break;

      default:
        setFocusedIndex(null);
        return;
    }

    event.preventDefault();
    event.stopPropagation();

    newPosition += itemsCount + 1;
    newPosition %= itemsCount + 1;
    setFocusedIndex(newPosition === itemsCount ? null : newPosition);
  };

  // 엔터키로 검색
  // > IME Composition이 끝난 후 호출되어야 하므로 keydown에서 하지 않고 keyup에서 함
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    // 무언가 선택이 된 상태, 가능하다면 빠른 검색을 수행
    if (focusedIndex != null) {
      // 히스토리 선택
      if (keyword === '') {
        const historyKeyword = history.history[focusedIndex];
        sendSubmitEvent(pageContext.screenName, 'search_recent_item', {
          ...pageContext.params,
          keyword: historyKeyword,
        });
        goToSearchResult(historyKeyword);
        return;
      }

      // 작가 선택
      if (result && focusedIndex < authorsCount) {
        const author = result.author.authors[focusedIndex];
        sendSubmitEvent(pageContext.screenName, 'instant_item', {
          ...pageContext.params,
          item: 'author',
          item_id: author.id,
          item_name: author.name,
          keyword,
        });
        goToInstantUrl(getAuthorUrl(author));
        return;
      }

      // 책 선택
      if (result && focusedIndex < itemsCount) {
        const book = result.book.books[focusedIndex - authorsCount];
        sendSubmitEvent(pageContext.screenName, 'instant_item', {
          ...pageContext.params,
          item: 'book',
          item_id: book.b_id,
          item_name: book.web_title_title,
          keyword,
        });
        goToInstantUrl(getBookUrl(book, focusedIndex));
        return;
      }
    }

    // 일반 검색을 수행
    if (keyword !== '') {
      sendSubmitEvent(pageContext.screenName, 'search', {
        ...pageContext.params,
        keyword,
      });
      goToSearchResult(keyword);
    }
  };

  // 포커스 명령형 핸들 설정
  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
  }));

  // Submit으로 검색
  // > 이 때에는 선택이 되어 있더라도 빠른 검색이 아닌 일반 검색을 수행해야함
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    sendSubmitEvent(pageContext.screenName, 'search', { ...pageContext.params, keyword });
    goToSearchResult(keyword);
  };

  // focus 여부 트래킹
  const onFocus = () => {
    sendClickEvent(pageContext.screenName, 'search', pageContext.params);
    setIsFocused(true);

    if (!inputRef.current) {
      return;
    }

    const searchInput = inputRef.current;

    // iOS에서 검색인풋 커서가 잘못된 위치에 표시되거나 사라지는 문제 수정
    searchInput.style.opacity = '0';
    setTimeout(() => {
      searchInput.style.opacity = '1';
    });
  };

  const onBlur = (event: FocusEvent) => {
    // SLP가 페이지 형태가 되어서 버튼 클릭해도 랜딩 전까지 닫히면 안됨
    if (isMobile) {
      return;
    }

    const relatedTarget = event.relatedTarget || document.activeElement;
    if (!event.currentTarget.contains(relatedTarget)) {
      setIsFocused(false);
    }
    setFocusedIndex(null);
  };

  // SLP가 닫히는 조건: URL이 변경되었을 때, PC, Mobile 웹에서 모두 문제 없음
  useEffect(() => {
    setIsFocused(false);
  }, [router.query]);

  // 기타 이벤트 핸들러
  const onKeywordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setKeyword(value);
  const onKeywordResetClick = () => {
    setKeyword('');
    inputRef.current?.focus();
  };

  const onMouseLeave = () => setFocusedIndex(null);
  const onCollapseClick = () => setIsFocused(false);
  const onBackdropClick = (event: MouseEvent<HTMLFormElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    setIsFocused(false);
  };

  // 검색어가 바뀔 때 검색 실행
  const dispatch = useAppDispatch();

  const throttledSearch = useMemo(
    () =>
      throttle<(keyword: string, isAdultExcluded: boolean) => void>((searchKeyword, isAdultExcludedSearch) => {
        dispatch(
          updateInstantSearchAction({
            reqParams: { keyword: searchKeyword, isAdultExcluded: isAdultExcludedSearch },
          }),
        );
      }, 1000),
    [dispatch],
  );

  useEffect(() => {
    if (keyword.trim() === '') {
      dispatch(resetInstantSearchAction());
      return;
    }

    throttledSearch(keyword, isAdultExcluded);
  }, [dispatch, throttledSearch, keyword, isAdultExcluded]);

  // 본문 스크롤 막기
  // 여기에서 overflow만 막고, iOS 용으로 formFocusedStyle에서 touch-action을 막음
  // * 검색개선 - 지금많이찾는작품 작업에서 태블릿 포함 모바일기기는 모바일 UI보여주는 것으로 변경
  useEffect(() => {
    const { position: previousOverflow, position: previousPosition } = document.body.style;

    if (isFocused && isMobileDevice) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';

      return () => {
        document.body.style.overflow = previousOverflow;
        document.body.style.position = previousPosition;
      };
    }

    return () => {};
  }, [isFocused, isMobileDevice]);

  const searchPopup =
    isFocused &&
    (keyword === '' || !result || itemsCount === 0 ? (
      <>
        <SearchHistoryExperimental
          css={styles.popupContentStyle}
          history={history}
          goToSearchResult={goToSearchResult}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
        />
        <SearchKeywordList
          data={searchComponentsData}
          error={searchComponentsError}
          isLoading={isLoadingSearchComponents}
        />
        {isMobileDevice && <SearchPopularBooks title="지금 많이 찾는 작품" />}
      </>
    ) : (
      <>
        <SearchResult
          css={styles.popupContentStyle}
          keyword={keyword}
          getAuthorUrl={getAuthorUrl}
          getBookUrl={getBookUrl}
          goToInstantUrl={goToInstantUrl}
          focusedIndex={focusedIndex}
          setFocusedIndex={setFocusedIndex}
          isAdultExcluded={isAdultExcluded}
          setIsAdultExcluded={setIsAdultExcluded}
        />
      </>
    ));

  return (
    <>
      <StyledSearchForm
        focused={isFocused}
        isHide={isHide}
        isMobileDevice={isMobileDevice}
        role="search"
        onClick={onBackdropClick}
        onBlur={onBlur}
        onSubmit={onSubmit}
        onMouseLeave={onMouseLeave}>
        {/* button이 포커스를 가지지 못하는 사파리에서 지우기 버튼 누를 시,
        focusTrap이 포커스를 먹게 하여 검색창이 닫히는 것을 방지 */}
        <div
          css={[
            styles.focusTrapStyle(isFocused && isMobileDevice),
            isFocused && isMobileDevice && styles.formFocusedMobileStyle(isFocused && isMobileDevice),
            !!searchPopup && isMobileDevice && styles.formOpenPopupStyle,
          ]}
          tabIndex={-1}>
          <StyledSearchBoxWrapper focused={isFocused} isMobile={isMobileDevice} isOpen={!!searchPopup}>
            <label css={styles.searchBoxShapeStyle(isFocused && isMobileDevice)}>
              <IconSearchSearch css={styles.searchIconStyle(isFocused && isMobileDevice)} />
              <span css={a11y}>인스턴트 검색</span>

              <input
                ref={inputRef}
                css={styles.searchBoxStyle}
                onFocus={onFocus}
                type="text"
                value={keyword}
                onChange={onKeywordChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                maxLength={MAX_SEARCH_KEYWORD_LENGTH}
                tabIndex={-1}
              />

              {isFocused && keyword !== '' && (
                <TrackClickEvent
                  screenName={pageContext.screenName}
                  target="search_reset_keyword"
                  params={{ ...pageContext.params, keyword }}>
                  <button
                    type="button"
                    onClick={onKeywordResetClick}
                    css={styles.resetButtonStyle(isFocused && isMobileDevice)}>
                    <IconSearchClear css={styles.clearStyle} aria-label="지우기" />
                  </button>
                </TrackClickEvent>
              )}
            </label>

            {isFocused && (
              <TrackClickEvent screenName={pageContext.screenName} target="search_cancel" params={pageContext.params}>
                <button
                  type="button"
                  css={styles.cancelButtonStyle(isFocused && isMobileDevice)}
                  onClick={onCollapseClick}>
                  취소
                </button>
              </TrackClickEvent>
            )}
          </StyledSearchBoxWrapper>
          {searchPopup && (
            <StyledSearchPopupWrapper focused={isFocused} isMobileDevice={isMobileDevice}>
              {searchPopup}
            </StyledSearchPopupWrapper>
          )}
        </div>
      </StyledSearchForm>
      {isFocused && <div css={styles.formPlaceholderStyle} />}
    </>
  );
});
