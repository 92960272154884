import * as styles from './SearchPopularBooksBSkeleton.styles';

const list = Array.from({ length: 10 }, () => null);

export const SearchPopularBooksSkeleton = () => (
  <ul css={styles.containerStyle}>
    {list.map((_, i) => (
      <li css={styles.bookContainerStyle} key={`item-${i.toString()}`}>
        <div css={styles.bookThumbnailStyle} />
        <div css={styles.bookOrderStyle} />
        <div css={styles.bookMetaDataStyle}>
          <div css={styles.bookTitleStyle} />
          <div css={styles.bookAuthorsStyle} />
        </div>
      </li>
    ))}
  </ul>
);
