import type { BookRenderDataPartial } from '@ridi-web/design-system/Book/cjs';
import {
  Book,
  BookAuthors,
  BookDefinition,
  BookObserverProvider,
  Portrait2InfoComponents,
  Portrait2InfoStyles,
} from '@ridi-web/design-system/Book/cjs';
import { addRDTTrackingURIQuery } from '@ridi-web/design-system/Utils/cjs';
import { useCallback, useMemo } from 'react';

import { useLoggedUser } from '@/hooks/useLoggedUser';
import { sendCustomEvent } from '@/utils/eventClient';

import { BooksBackendOverrideStyles } from './SearchPopularBooksB.styles';
import * as styles from './SearchPopularBooksB.styles';
import { SearchPopularBooksSkeleton } from './SearchPopularBooksBSkeleton';

type SearchPopularBooksProps = {
  isPending?: boolean;
  books: BookRenderDataPartial[];
  tabName: string;
  subTabName: string;
};

export const SearchPopularBooks = ({ isPending, books, tabName, subTabName }: SearchPopularBooksProps) => {
  const user = useLoggedUser();
  const isAdultVerifiedUser = useMemo(() => (user && user.adultVerification.status === 'VERIFIED') ?? false, [user]);

  const noBadge = useCallback(() => <div />, []);

  const definition = useMemo(
    () => ({
      attributes: {
        isAdultVerifiedUser,
      },
      styles: [Portrait2InfoStyles, BooksBackendOverrideStyles],
      components: {
        ...Portrait2InfoComponents,
        BookBadge: noBadge,
        BookSetbook: () => null,
        BookAuthors: BookAuthors.withOptions({ clipBreakPoint: 1, clipCount: 1 }),
      },
    }),
    [noBadge, isAdultVerifiedUser],
  );

  const handleClick = useCallback(
    ({ item_id, item_title, item_index }: { item_id: string; item_title: string; item_index: number }) => {
      sendCustomEvent('search', 'select', 'popular_item', {
        item_id,
        item_title,
        item_index,
        current_tab: tabName,
        current_sub_tab: subTabName,
      });

      globalThis.location.href = addRDTTrackingURIQuery(`/books/${item_id}`, {
        sectionId: 'search_popular',
      });
    },
    [tabName, subTabName],
  );

  if (isPending) {
    return <SearchPopularBooksSkeleton />;
  }

  return (
    <BookObserverProvider>
      <BookDefinition definition={definition}>
        {({ className }) => (
          <ol className={className} css={styles.listContainerStyle}>
            {books.map((book, i) => (
              <li css={styles.bookContainerStyle} key={book.bookId}>
                <button
                  type="button"
                  css={styles.bookContainerStyle}
                  key={book.bookId}
                  onClick={() =>
                    handleClick({
                      item_id: book.bookId ?? '',
                      item_title: book.title ?? '',
                      item_index: i + 1,
                    })
                  }>
                  <Book data={book} />
                  <div css={styles.bookOrderStyle}>{i + 1}</div>
                </button>
              </li>
            ))}
          </ol>
        )}
      </BookDefinition>
    </BookObserverProvider>
  );
};
