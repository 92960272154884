import { ChangeEvent, useRef } from 'react';

import { FrequentlySearchedBooksCategoryItem } from '@/queries/search/useFrequentlySearcedBooksQuery';
import { ReactComponent as IconDropdown } from '~/public/svgs/search/Dropdown.svg';

import * as styles from './FakeSelect.styles';

type FakeSelectProps = {
  label: string;
  list: FrequentlySearchedBooksCategoryItem[];
  value: string;
  onChange(category: FrequentlySearchedBooksCategoryItem): void;
};

// 모바일에서 팝업으로 뜨는 HTMLSelectElement를 숨겨서 이용하기 때문에 PC에서 사용하려면 추가 스타일링 필요
export const FakeSelect = ({ label, list, value, onChange }: FakeSelectProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const item = list.find(({ category }) => category === ev.target.value);

    if (item) {
      onChange(item);
    }
  };

  return (
    <div css={styles.selectContainerStyle}>
      <select css={styles.selectStyle} onChange={handleChange} ref={selectRef} defaultValue={value}>
        {list.map(item => (
          <option key={item.category} value={item.category}>
            {item.title}
          </option>
        ))}
      </select>
      <button type="button" css={styles.subTabButtonStyle}>
        <span>{label}</span>
        <IconDropdown css={styles.iconDropdownStyle} />
      </button>
    </div>
  );
};
