import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useSearchImprovementExperiment } from '@/components/common/Search/hooks/useSearchImprovementExperiment';
import { RequestError, requestWithRefreshAuth } from '@/utils/request';

type UseSearchComponentsProps = {
  page: 'landing' | 'typing';
  platform: 'android' | 'ios' | 'onepun_on' | 'onepun_off' | 'web';
};

export enum SearchComponentFlag {
  Keyword = 'LANDING_PAGE/KEYWORD_FINDER/KEYWORD_RECOMMENDATION',
  Genre = 'LANDING_PAGE/KEYWORD_FINDER/GENRE_RECOMMENDATION',
}

export type SearchComponentKeywordRecommendationData = {
  genreColorCode: string;
  genreName: string;
  id: number;
  landingUrl: string;
  name: string;
  setId: number;
  setName: string;
};

export type SearchComponentGenreRecommendationData = {
  genre: string;
  landingUrl: string;
  name: string;
  setId: number;
  setName: string;
};

// FIXME: type에 대한 타입 정리
export type SearchComponent =
  | {
      type: SearchComponentFlag.Keyword;
      name: string;
      data: {
        tags: SearchComponentKeywordRecommendationData[];
      };
    }
  | {
      type: SearchComponentFlag.Genre;
      name: string;
      data: {
        tags: SearchComponentGenreRecommendationData[];
      };
    };

type UseSearchComponentsResponse = {
  components: SearchComponent[];
};

type UseSearchComponentsRequestError = RequestError<{
  message: string;
}>;

export const useSearchComponentsQuery = ({ page, platform }: UseSearchComponentsProps) => {
  const { data: flags, isFallback } = useSearchImprovementExperiment();
  const searchComponentsFeatureFlags: SearchComponentFlag[] = flags;

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(
    ['/api/search/components', page, platform],
    () =>
      requestWithRefreshAuth<UseSearchComponentsRequestError, UseSearchComponentsResponse>({
        host: process.env.NEXT_PUBLIC_MAIN_DOMAIN,
        method: 'GET',
        pathname: `/api/search/components?${new URLSearchParams({ page, platform }).toString()}`,
      }).then(([responseError, res]) => {
        if (responseError) {
          throw responseError;
        }

        return res.data.components.filter(item => searchComponentsFeatureFlags.includes(item.type));
      }),

    {
      enabled: Boolean(flags?.length),
    },
  );

  const result = useMemo(() => {
    try {
      return Object.groupBy(data, ({ type }) => type);
    } catch {
      return {};
    }
  }, [data]);

  return useMemo(
    () => ({
      data: {
        [SearchComponentFlag.Keyword]: result[SearchComponentFlag.Keyword]?.[0] ?? null,
        [SearchComponentFlag.Genre]: result[SearchComponentFlag.Genre]?.[0] ?? null,
      },
      isLoading,
      isFallback,
      error,
    }),
    [result, isFallback, isLoading, error],
  );
};
