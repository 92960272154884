import { css } from '@emotion/react';
import { compileStyleList, createOverrideModularStyle, DefaultStyleList } from '@ridi-web/design-system/Book/cjs';

import { RIDITheme } from '@/components/styles/themes';

export const gridStyle = css`
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  list-style-type: none;
`;

export const bookContainerStyle = css`
  position: relative;
  /* 3컬럼이면 2 gap이 나와서 16px, 3컬럼이 나눠서 계산해야 하니 16 / 3 = 5.3333... 이지만 편의상 차이가없는 5.4px 사용 */
  width: calc(100% / 3 - 5.4px);
`;

export const bookButtonStyle = css`
  cursor: pointer;
  width: 100%;
  text-align: left;
`;

export const BooksBackendOverrideStyles = compileStyleList('BooksBackendOverrideStyles', {
  TitleWrapper: createOverrideModularStyle(
    DefaultStyleList.TitleWrapper,
    css`
      width: 100%;
    `,
  ),
  Title: createOverrideModularStyle(
    DefaultStyleList.Title,
    css`
      /* books-backend override */
      font-size: 14px;
    `,
  ),
});

export const authorStyle = (theme: RIDITheme) => css`
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
