import { css } from '@emotion/react';
import { compileStyleList, createOverrideModularStyle, DefaultStyleList } from '@ridi-web/design-system/Book/cjs';

import type { RIDITheme } from '@/components/styles/themes';

export const bookContainerStyle = css`
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 66px;
  cursor: pointer;
  text-align: left;
  min-width: 0;
  flex: 1;
`;

export const bookOrderStyle = (theme: RIDITheme) => css`
  position: absolute;
  left: 46px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.01em;
  min-width: 50px;
  min-height: 100%;
  color: ${theme.colors.grey600};
`;

export const listContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 16px;
  list-style-type: none;
`;

export const BooksBackendOverrideStyles = compileStyleList('BooksBackendOverrideStyles', {
  Renderer: createOverrideModularStyle(
    DefaultStyleList.Renderer,
    css`
      flex-direction: row;
      height: 66px;
      min-width: 0;
      flex: 1;
    `,
  ),
  RendererCoverWrapper: createOverrideModularStyle(
    DefaultStyleList.RendererCoverWrapper,
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 46px;
      max-width: 46px;
      height: 66px;
      border-radius: 4px;
      padding-bottom: initial;
    `,
  ),
  CoverRenderer: createOverrideModularStyle(
    DefaultStyleList.CoverRenderer,
    css`
      position: relative;
      width: 100%;
    `,
  ),
  ThumbnailImage: createOverrideModularStyle(
    DefaultStyleList.ThumbnailImage,
    css`
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
    `,
  ),
  ThumbnailOverlay: createOverrideModularStyle(DefaultStyleList.ThumbnailOverlay, css``),
  MetadataRenderer: createOverrideModularStyle(
    DefaultStyleList.MetadataRenderer,
    css`
      margin-top: 0;
      padding-left: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 0;
      flex: 1;
    `,
  ),
  TitleWrapper: createOverrideModularStyle(
    DefaultStyleList.TitleWrapper,
    css`
      width: 100%;
    `,
  ),
  Title: createOverrideModularStyle(
    DefaultStyleList.Title,
    css`
      font-size: 14px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    `,
  ),
  AdultBadge: createOverrideModularStyle(
    DefaultStyleList.AdultBadge,
    css`
      right: 3.8px;
      top: 3.8px;
      left: auto;
      width: 15px;
      height: 15px;
    `,
  ),
});

export const adultBadgeStyle = (theme: RIDITheme) => css`
  position: absolute;
  right: 3.8px;
  top: 3.8px;
  left: auto;
  width: 15px;
  height: 15px;
  z-index: 1;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 100%;
  color: white;
  opacity: 0.4;
  background: ${theme.colors.grey600};
  font-size: 5.3px;
`;

export const authorStyle = (theme: RIDITheme) => css`
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
