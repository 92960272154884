import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useSearchHistory } from './useSearchHistory';

// SLP에서 최근 검색어 클릭시 페이지 이동전에 최근 검색어 순서가 바뀌어 보이는 것을 방지하기 위해
// history 필드의 업데이트 조건에 제약 추가
// https://ridi.slack.com/archives/C08016KAH7T/p1735291266603109
// TODO: 실험 끝나면 useSearchHistory 훅에 반영
export const useSearchHistoryForSLP = (isExperimental: boolean) => {
  const router = useRouter();
  const history = useSearchHistory();
  const [lockedHistory, setLockedHistory] = useState<string[]>([]);

  // 히스토리 초기화되면 초기화
  useEffect(() => {
    if (history.historyEnabled) {
      setLockedHistory(history.history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.historyEnabled]);

  useEffect(() => {
    // 히스토리를 삭제했을때 동기화
    if (lockedHistory.length > history.history.length) {
      setLockedHistory(history.history);
    }
  }, [lockedHistory, history.history]);

  // 라우터가 변경되었을때 동기화
  useEffect(() => {
    setLockedHistory(history.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  if (!isExperimental) {
    return history;
  }

  return {
    ...history,
    history: lockedHistory,
  };
};
