import { useSingleFeatureFlag } from '@/hooks/useSingleFeatureFlag';

export const useSearchImprovementExperiment = () => {
  const { isPending, error, data } = useSingleFeatureFlag('servicebackends-search-components');

  return {
    /** 사용처에서 간단하게 사용할 수 있게 기본값을 할당합니다. 사용시 꼭 isPending과 isFallback을 확인해야 합니다. */
    data: data?.landing ?? [],
    isFallback: !!error || (!isPending && !data?.landing?.length), // 레이아웃을 얻지 못하면 기존 레이아웃으로 동작
    isPending,
  };
};
