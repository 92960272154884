import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

export const bookContainerStyle = css`
  display: flex;
  flex-direction: row;
  min-height: 38px;
  cursor: pointer;
  overflow: hidden;
`;

export const bookButtonStyle = css`
  width: 100%;
`;

export const bookOrderStyle = (theme: RIDITheme) => css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  min-width: 32px;
  min-height: 100%;
  color: ${theme.colors.grey600};
`;

export const bookTitleWrapperStyle = css`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const bookTitleStyle = (theme: RIDITheme) => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.grey600};
  line-height: 1.4em;
`;
