import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

import { keywordListTitleStyle } from '../SearchKeywordList/SearchKeywordList.styles';

export const titleStyle = keywordListTitleStyle;

export const containerStyle = css`
  padding-bottom: 200px;
`;

export const categoryContainerStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;
`;

export const categoryTabListStyle = css`
  display: flex;
  flex-direction; row;
`;

export const tabItemStyle = (focused: boolean) => (theme: RIDITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 32px;

  font-size: 13px;
  font-weight: ${focused ? 600 : 500};
  letter-spacing: -0.01em;
  border-radius: 9999px;

  color: ${focused ? theme.colors.white : theme.colors.grey500};
  background: ${focused ? theme.colors.grey600 : 'transparent'};
`;
