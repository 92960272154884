import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * TODO:
 * localStorage에 스키마와 기본값과 타입을 지정해서 사용하기 위한 훅
 * src/utils/storage에 runtypes에 의존하면서 같은 기능을 하는 함수가 이미 있어서
 * 타입 스키마 라이브러리가 정해지면 통합을 하는 것이 좋을 것 같음
 */
export const useLocalStorage = <Type>(
  key: string,
  defaultValue: Type,
  validator?: (value: Type) => boolean,
): [Type, (params: Type | ((params: Type) => Type)) => void] => {
  const isInited = useRef(false);
  const [data, setData] = useState<Type>(() => {
    try {
      const savedData = JSON.parse(localStorage.getItem(key)!) as Type;
      if (validator && !validator(savedData)) {
        throw new Error('Invalid data');
      }

      return savedData;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (!isInited.current) {
      isInited.current = true;
    }

    localStorage.setItem(key, JSON.stringify(data));
  }, [data, key]);

  const setFunction = (params: Type | ((params: Type) => Type)) => {
    const value = typeof params === 'function' ? (params as (p: Type) => Type)(data) : params;
    if (validator && !validator(value)) {
      return;
    }

    setData(value);
  };
  const setRef = useRef(setFunction);
  setRef.current = setFunction;

  const set = useCallback((params: Type | ((params: Type) => Type)) => {
    setRef.current(params);
  }, []);

  return [data, set];
};
