import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const selectContainerStyle = css`
  position: relative;
`;

export const selectStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
`;

export const subTabButtonStyle = (theme: RIDITheme) => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 8px;
  min-width: 64px;
  height: 28px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.01em;

  color: ${theme.colors.fillSecondary};
  border: 1px solid ${theme.colors.grey300};
  border-radius: 5px;

  span {
    flex: 1;
    text-align: left;
  }

  &:after {
    width: 9px;
    height: 9px;
    margin-left: 3px;
  }
`;

export const iconDropdownStyle = css`
  margin-left: 3px;
`;
