import { css } from '@emotion/react';

import type { RIDITheme } from '@/components/styles/themes';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 8px 16px;
`;

export const bookContainerStyle = css`
  display: flex;
  flex-direction: row;
`;

export const bookOrderStyle = (theme: RIDITheme) => css`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: ${theme.colors.fillSkeletonElevated};
`;

export const bookTitleStyle = (theme: RIDITheme) => css`
  margin-left: 12px;
  flex: 1;
  width: 100%;
  max-width: 219px;
  max-height: 20px;
  border-radius: 4px;
  background: ${theme.colors.fillSkeletonElevated};
`;
