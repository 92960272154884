import { CamelizedBackendsBadge } from '@/models/backendsApi/BackendsBadgeType';
import { CamelizedBackendsBook } from '@/models/backendsApi/BackendsBookType';
import { createQuery } from '@/queries/utils';
import { RequestError, requestWithRefreshAuth } from '@/utils/request';

type UseFrequentlySearcedBooksRequestError = RequestError<{
  message: string;
}>;

export type FrequentlySearchedBooksCategoryItem = {
  bookItems: {
    book: CamelizedBackendsBook;
    badges: CamelizedBackendsBadge[];
  }[];
  category: string;
  categoryIds: number[];
  title: string;
};

export type FrequentlySearchedBooksCategory = {
  categoryIds: number[];
  categoryItems: FrequentlySearchedBooksCategoryItem[];
  genre: string;
  title: string;
};

type UseFrequentlySearcedBooksResponseData = {
  data: FrequentlySearchedBooksCategory[];
};

export const [useFrequentlySearchedBooksQuery] = createQuery(
  ({ platform }: { platform: 'web'; isVerifiedAdult: boolean | null }) => [
    `/v2/search/frequently-searched-books/${platform}`,
  ],
  ({ platform, isVerifiedAdult }) =>
    () =>
      requestWithRefreshAuth<UseFrequentlySearcedBooksRequestError, UseFrequentlySearcedBooksResponseData>({
        host: process.env.NEXT_PUBLIC_BACKENDS_API,
        method: 'GET',
        pathname: `/v2/search/frequently-searched-books?platform=${platform}&is_verified_adult=${isVerifiedAdult ? 'true' : 'false'}`,
      }).then(([responseError, res]) => {
        if (responseError) {
          throw responseError;
        }

        return res.data.data;
      }),
);
